import React, { useEffect } from 'react'
import ContactForm from './ContactFourm';
import Navigation from './Navigation';
import '../App.css';

function ContactPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    
    return (
      <div id="contact" data-aos="fade-up">
        <div>
          <header><Navigation/></header>
        </div>
        <h1>Elite package</h1>
        <h2> What we offer in this package:</h2>
        <h3><ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
          <li>10 Pages</li>
          <li>Image Integration</li>
          <li>Responsive Design</li>
          <li>SEO Optimization</li>
          <li>Advanced Security Features</li>
          <li>Contact Form</li>
          <li>Performance Optimization</li>
          <li>+$60/extra page</li>
        </ul>
        </h3>
        <p data-aos="fade-up">
        Our Elite Package is designed for businesses that demand the highest level of online presence. 
      This package includes ten custom-designed pages that are fully responsive, ensuring they look 
      and perform excellently on any device. We integrate high-quality images to enhance the visual 
      appeal of your site and provide SEO optimization to improve your search engine rankings. 
      Additionally, we include advanced security features to protect your site, a contact form for 
      easy communication with clients, and performance optimization to ensure your site runs smoothly. 
      For any additional pages, we offer a competitive rate of $60 per page.
        </p>

        <div>
            <ContactForm/>
        </div>
    </div>
    );
}

export default ContactPage;