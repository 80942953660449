import React, { useEffect } from 'react'
import ContactForm from './ContactFourm';
import Navigation from './Navigation'
import '../App.css';

function ContactPage() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    
    return (
      <div id="contact" data-aos="fade-up">
        <div>
          <header><Navigation/></header>
        </div>
        <h1>Business up package</h1>
        <h2> What we offer in this package:</h2>
        <h3><ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
          <li>5 Pages</li>
          <li>Image Integration</li>
          <li>Responsive Design</li>
          <li>SEO Optimization</li>
          <li>Security Features</li>
          <li>Contact Form</li>
          <li>+$70/extra page</li>
        </ul>
        </h3>
        <p data-aos="fade-up">
        Our Business Up Package is tailored for growing businesses that need a robust online presence. 
          This package includes five custom-designed pages that are fully responsive, ensuring optimal 
          performance on all devices. We integrate high-quality images to enhance your site's visual appeal 
          and provide SEO optimization to boost your search engine rankings. Additionally, we include 
          essential security features to protect your site and a contact form to facilitate easy communication 
          with your clients. For any additional pages, we offer a competitive rate of $70 per page.
        </p>

        <div>
            <ContactForm/>
        </div>
    </div>
    );
}

export default ContactPage;