import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Nav, Button, Offcanvas, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactFourm';
import logo from './logo.png';

const Navigation = () => {
    const [show, setShow] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 991);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const yOffset = -70; // Adjust this value to match the height of your fixed header
            const yPosition = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: yPosition, behavior: 'smooth' });
        }
    };

    const location = useLocation();

    const handleNavClick = (section) => {
        if (location.pathname === '/' || location.pathname === '/home.js') {
            scrollToSection(section);
        } else {
            window.location.href = `/${section}`;
        }
    };

    return (
        <>
            <Navbar expand="lg" style={{ backgroundColor: 'black' }} className="justify-content-left">
                <Container className="justify-content-left">
                    <Navbar.Brand href="/">
                        <img
                            src={logo}
                            width="100"
                            height="70"
                            className="d-inline-block align-top align-center"
                            alt=""
                        />
                    </Navbar.Brand>
                    {isMobile ? (
                        <Button variant="light" onClick={handleShow}>
                            Menu
                        </Button>
                    ) : (
                        <Navbar.Collapse className="justify-content-center">
            <Nav className="ml-auto">
                <Nav.Link href="/" style={{ color: 'white' }}>Home</Nav.Link>
                <Nav.Link onClick={() => handleNavClick('about')} style={{ color: 'white', whiteSpace: 'nowrap', marginRight: '5px' }}>About Us</Nav.Link>
                <Nav.Link onClick={() => handleNavClick('packages')} style={{ color: 'white' }}>Packages</Nav.Link>
                <Nav.Link onClick={() => handleNavClick('contact')} style={{ color: 'white' }}>Presence</Nav.Link>
                <Link to={'/contactfourmwithnav'}>
                    <Button variant="outline-light" className="ml-2" id='navbutton'>Get a Quote</Button>
                </Link>
            </Nav>
        </Navbar.Collapse>
                    )}
                </Container>
            </Navbar>

            {isMobile && (
                <Offcanvas show={show} onHide={handleClose} style={{ backgroundColor: 'black' }} >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Menu</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                    <Nav className="flex-column">
        <Nav.Link as={Link} to="/" onClick={handleClose} style={{ color: 'white' }}>Home</Nav.Link>
        <Nav.Link as={Link} to="/aboutpage" onClick={handleClose} style={{ color: 'white' }}>About Us</Nav.Link>
        <Nav.Link as={Link} to="/price" onClick={handleClose} style={{ color: 'white' }}>Packages</Nav.Link>
        <Nav.Link as={Link} to="/contactpage" onClick={handleClose} style={{ color: 'white' }}>Presence</Nav.Link>
        <Link to="/contactfourmwithnav">
          <Button variant="outline-light" className="mt-2" onClick={handleClose}>Get a Quote</Button>
        </Link>
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            )}
        </>
    );
};

export default Navigation;
