import React from 'react';
import "../App.css"

function Intro() {
  return (
    <div id="intro" className='intro'>
      <h1 data-aos="fade-up">WebDevCC</h1>
      <p data-aos="fade-up">We create Websites for start ups and established businesses in North America.</p>
    </div>
  );
}

export default Intro;