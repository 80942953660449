import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
            <div className="container text-center">
                <p>© {new Date().getFullYear()} WebDevCC. All rights reserved.</p>
                <ul className="list-inline">
                <li className="list-inline-item"><a href="/" className="text-white">Home</a></li>
                    <li className="list-inline-item">
                        <Link to="/aboutpage" className="text-white">About Us</Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="/contactpage" className="text-white">Presence</Link>
                    </li>
                    <li className="list-inline-item">
                        <Link to="/contactfourmwithnav" className="text-white">Contact</Link>
                    </li>
                </ul>
            </div>
        </footer>
  );
};

export default Footer;
