import React, { useEffect } from 'react'
import ContactForm from './ContactFourm';
import Navigation from './Navigation';
import '../App.css';

function AboutPage() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

    return (
      <div id="aboutPage" data-aos="fade-up">
    
          <header>
            <Navigation/>
            </header>
        <h1>About Us!</h1>
        <p data-aos="fade-up">
  At Webdevcc, we are passionate about creating stunning, functional websites that help businesses thrive online. 
  Our team of experienced developers and designers work closely with clients to understand their unique needs and 
  deliver customized solutions. We believe in the power of technology to transform businesses and are committed to 
  helping our clients achieve their digital goals. Our approach is client-centric, ensuring that every project we 
  undertake is tailored to meet the specific objectives and vision of our clients. We stay up-to-date with the latest 
  industry trends and technologies to provide innovative and effective solutions. Whether you are a small start-up or 
  a large enterprise, we are dedicated to providing you with the tools and support you need to succeed in the digital 
  landscape. Join us on this journey and let us help you make your mark online.
</p>

<div>
            <ContactForm/>
            </div>
    </div>
    
    );
}

export default AboutPage;