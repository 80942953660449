import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import "../App.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import Navigation from './Navigation';

function Portfolio() {
  const [activeIndex, setActiveIndex] = useState(null);

  const projects = [
    {
      title: 'Starter Package - CAD$199',
      description: (
        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
          <li>3 Pages</li>
          <li>Image Integration</li>
          <li>Responsive Design</li>
          <li>SEO Optimization</li>
          <li>+80/extra page</li>
        </ul>
      ),
      projectUrl: '/PackageOne'
    },
    {
      title: 'Business Package - CAD$399',
      description: (
        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
          <li>5 Pages</li>
          <li>Image Integration</li>
          <li>Responsive Design</li>
          <li>SEO Optimization</li>
          <li>Security Features</li>
          <li>Contact Form</li>
          <li>+$70/extra page</li>
        </ul>
      ),
      projectUrl: '/PackageTwo'
    },
    {
      title: 'Elite Package - CAD$599',
      description: (
        <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
          <li>10 Pages</li>
          <li>Image Integration</li>
          <li>Responsive Design</li>
          <li>SEO Optimization</li>
          <li>Advanced Security Features</li>
          <li>Contact Form</li>
          <li>Performance Optimization</li>
          <li>+$60/extra page</li>
        </ul>
      ),
      projectUrl: '/PackageThree'
    },
  ];

  const items = projects.map((project, index) => (
    <Link to={project.projectUrl} key={index} style={{ textDecoration: 'none' }}>
      <div
        id='packages'
        className={`item ${activeIndex === index ? 'active' : ''}`}
        data-value={index + 1}
        onMouseEnter={() => setActiveIndex(index)}
        onMouseLeave={() => setActiveIndex(null)}
        style={{ 
          transition: 'transform 0.5s ease-in-out',
          width: '280px',  // Set a fixed width for all cards
          height: '400px', // Set a fixed height for all cards
          cursor: 'pointer', // Make the cursor a pointer to indicate clickability
          backgroundImage: `${project.image}`, // Set the background image
          backgroundColor:'#4067e6',
          backgroundSize: 'cover', // Ensure the image covers the entire card
          backgroundPosition: 'center', // Center the image
          color: 'white', // Ensure text is readable
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '10px',
          textAlign: 'center' // Center the text
        }}
      >
        <h3>{project.title}</h3>
        <br></br>
        {project.description}
      </div>
    </Link>
  ));

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  return (
    
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={responsive}
      controlsStrategy="alternate"
      autoPlay
      autoPlayInterval={2000}
      infinite
    />
  );
}

export default Portfolio;
