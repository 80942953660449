import React, { useEffect } from 'react'
import ContactForm from './ContactFourm';
import '../App.css';
import Navigation from './Navigation';

function ContactPage() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    
    return (
      <div id="contact" data-aos="fade-up">
        <div>
          <header><Navigation/></header>
        </div>
        <h1>Start up package</h1>
        <h2> What we offer in this package:</h2>
        <h3><ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
          <li>3 Pages</li>
          <li>Image Integration</li>
          <li>Responsive Design</li>
          <li>SEO Optimization</li>
          <li>+80/extra page</li>
        </ul>
        </h3>
        <p data-aos="fade-up">
        Our Start-up Package is designed to give your business a strong online presence. 
          It includes three professionally designed pages that are fully responsive, ensuring 
          they look great on any device. We integrate high-quality images to enhance the visual 
          appeal of your site. Additionally, our SEO optimization services will help improve 
          your site's visibility on search engines, driving more traffic to your business. 
          For any additional pages, we offer a competitive rate of $80 per page.
        </p>
        <div>
            <ContactForm/>
            </div>
    </div>
    );
}

export default ContactPage;