import React from 'react';
import ContactPage from './ContactPage';
import { Link } from 'react-router-dom';
import "../App.css"

function Contact() {
  return (
    <div id="contact" data-aos="fade-up">
      <h1>Why Have a Website</h1>
      <h4>In today's digital age, having a website is crucial for any business. A well-designed website:</h4>
      <p data-aos="fade-up"><b>Enhances Credibility:</b> A professional website establishes your business as a legitimate entity.</p>
      <p data-aos="fade-up"><b>Increases Visibility:</b> It makes your business accessible to a global audience 24/7.</p>
      <p data-aos="fade-up"><b>Boosts Sales:</b> An online presence can drive more sales by reaching a broader audience.</p>
      <p data-aos="fade-up"><b>Improves Customer Engagement:</b> It provides a platform for customers to interact with your business, ask questions, and provide feedback.</p>
      <p data-aos="fade-up"><b>Showcases Your Brand:</b>  A website is a perfect place to highlight your products, services, and brand story.</p>

      <Link to='/contactpage'>
      <button data-aos="fade-up" >Click to learn more!</button>
      </Link>
      <form>
        {/* Form elements go here */}
      </form>
    </div>
  );
}

export default Contact;