import React, { useEffect } from 'react'
import ContactForm from './ContactFourm';
import Navigation from './Navigation';
import '../App.css';

function ContactPage() {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

    return (
      <div id="contact" data-aos="fade-up">
        <div>
          <header><Navigation/></header>
        </div>
        <h1>Why Have a Website</h1>
        <h4>In today's digital age, having a website is crucial for any business. A well-designed website:</h4>
        <p data-aos="fade-up"><b>Enhances Credibility:</b> A professional website establishes your business as a legitimate entity. It shows that you are serious about your business and provides potential customers with confidence in your products or services.</p>
<p data-aos="fade-up"><b>Increases Visibility:</b> It makes your business accessible to a global audience 24/7. This means that customers can find information about your business, products, or services at any time, from anywhere in the world.</p>
<p data-aos="fade-up"><b>Boosts Sales:</b> An online presence can drive more sales by reaching a broader audience. With e-commerce capabilities, you can sell products directly through your website, making it easier for customers to purchase from you.</p>
<p data-aos="fade-up"><b>Improves Customer Engagement:</b> It provides a platform for customers to interact with your business, ask questions, and provide feedback. This interaction can help build stronger relationships with your customers and improve customer satisfaction.</p>
<p data-aos="fade-up"><b>Showcases Your Brand:</b> A website is a perfect place to highlight your products, services, and brand story. You can use it to share your mission, values, and what sets you apart from competitors, helping to build a strong brand identity.</p>
<div>
            <ContactForm/>
            </div>
    </div>
    );
}

export default ContactPage;