import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../App.css";

function About() {
  const navigate = useNavigate(); // Hook to programmatically navigate

  const handleClick = () => {
    navigate('/aboutpage'); // Navigate to AboutPage route
  };

  return (
    <div data-aos="fade-up" id="about" className='about'>
      <h1 data-aos="fade-up">About Us</h1>
      <p data-aos="fade-up">At Webdevcc, we are passionate about creating stunning, functional websites that help businesses thrive online. Our team of experienced developers and designers work closely with clients to understand their unique needs and deliver customized solutions. We believe in the power of technology to transform businesses and are committed to helping our clients achieve their digital goals.
      </p>
      
      {/* Link component for navigation */}
      <Link to="/aboutpage">
        <button data-aos="fade-up">Click to learn more!</button>
      </Link>
      <br/>
      <br/>
      <br/>
    </div>
  );
}

export default About;
